/* .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
	background-color: rgba(187, 0, 0, 0.7);
} */
/* .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
	background-color: rgba(187, 0, 0, 0.7);
}
@media (hover: none) {
	.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
		background-color: rgba(187, 0, 0, 0.7);
	}
} */
/* .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {

        background-color: rgba(187, 0, 0, 0.3) !important
} */
.MuiDataGrid-columnsContainer {
    background-color: rgb(187, 0, 0);
	color: rgb(255, 255, 255);
}
label {
	color: var(--root-text-color) !important
}
.scarlettable > div:first-child{
	overflow: auto !important;
	height: auto !important;
	width: auto !important;

}
/* .visible.menu.transition{
	z-index: 999;
} */